import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const BillsPage = ({ userId }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [webUrl, setWebUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const token = localStorage.getItem("token");
        const userDataString = localStorage.getItem("user");
        const userData = JSON.parse(userDataString);
        const userId = userData.unique;

        // Fetch user balance
        if (token) {
          const balanceResponse = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/balance/user/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBalance(
            balanceResponse.data.response.length > 0
              ? balanceResponse.data.response[0].balance
              : 0
          );
        }

        // Fetch bills
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/admin/bill/view-bill/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if there are bills and update state accordingly
        if (response.data.response.length === 0) {
          setBills([]); // No bills available
          toast.info("No bills available.");
        } else {
          setBills(response.data.response);
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.response
          : "An unexpected error occurred.";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchBills();
  }, [userId]);

  const handlePayments = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.success === true &&
        (response.data.response === "You have already paid." ||
          response.data.response === "Already Paid" ||
          response.data.response === "Sorry you have no active reservations" ||
          response.data.response === "Bill details not found!")
      ) {
        toast.error(response.data.response);
      } else {
        setWebUrl(response.data.response);
        toast.success("Please Complete Payment");
        setShowIframe(true);
      }
    } catch (error) {
      // Handle the error appropriately
      const errorMessage = error.response
        ? error.response.data.response
        : "An unexpected error occurred.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/user/home");
  };

  const handlePayViaWallet = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/payviawallet/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Payment successful via wallet!");
      } else {
        toast.error(response.data.response);
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.response
        : "An unexpected error occurred.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-fulls">
          <div className="bg-white shadow-md rounded-md p-6 w-fulls">
            <div className="flex items-center justify-between mb-4 w-fulls">
              <h2 className="text-lg font-semibold">Bill Details</h2>
              <span className="text-1xl font-semibold mb-8">
                Date: {new Date().toLocaleDateString()}
              </span>
            </div>
            {loading ? (
              <div className="bg-white shadow-md rounded-md p-4">
                <p className="text-lg font-semibold mb-4">Payment</p>
                <p className="text-gray-700 mb-2">Loading...</p>
                <div className="flex flex-col">
                  <button
                    onClick={handlePayments}
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-600"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Pay for bills"}
                  </button>
                </div>
              </div>
            ) : (
              <>
                {bills.length === 0 ? (
                  <div className="flex flex-col">
                    <button
                      onClick={handlePayments}
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-blue-600"
                      }`}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay for deposit instead"}
                    </button>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Water Bill:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.WaterBill}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Rent Bill:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.RentBill}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Total Amount:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.TotalAmount}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <button
                        onClick={handlePayments}
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                          isLoading
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-blue-600"
                        }`}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Pay for bills"}
                      </button>
                    </div>
                  </div>
                )}

                {/* Show the Pay via Wallet button if balance is sufficient */}
                {balance >= bills.TotalAmount && (
                  <div className="mt-4">
                    <button
                      onClick={handlePayViaWallet}
                      className={`bg-green-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-green-600"
                      }`}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay via Wallet"}
                    </button>
                  </div>
                )}
              </>
            )}

            {/* Conditionally render the iframe with close button */}
            {showIframe && webUrl && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <button
                  onClick={handleCloseIframe}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
                >
                  Close
                </button>
                <iframe
                  src={webUrl}
                  className="w-screen h-screen border-none mx-auto"
                  title="Payment Iframe"
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default BillsPage;
